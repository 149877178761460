import React, { useContext, useEffect, useState } from "react";
import { Col, Image, Row, Button } from "react-bootstrap";
import "./documents.scss";
import * as _api from "../../Apis/Documents_apis";
import FoldersList from "./FoldersList";

const Documents = () => {
  const [showModalFolAdd, setShowModalFolAdd] = useState(false);
  const handleModalFolAdd = (status) => setShowModalFolAdd(status); //fonction à passer au child pour MAJ status modal

  return (
    <>
      <Row className="header">
        <Col sm={5}>
          <Image
            className="img_title"
            src="images/icons/bigmenu_documents-formation.svg"
          />
          <span className="title">Documents</span>
        </Col>
        <Col sm={7}>
          {/* <a href="#">Accéder à l'interface de rangement</a>&nbsp;
          <a href="#">Voir les articles en cours (x)</a>&nbsp; */}
        </Col>
      </Row>
      <Row>
      <FoldersList />
      </Row>
    </>
  );
};

export default Documents;
