import React, { useState } from "react";
import Loading from "../../Helpers/Loading";
import { Button, Col, Image, Row } from "react-bootstrap";
import { BsPlusLg } from "react-icons/bs";
import EntrainementsListCompetitor from "./EntrainementsListCompetitor";
import { userContext } from "../../Stores/Store";
import { useContext } from "react";
import EntrainementsListStaff from "./EntrainementsListStaff";
import EntrainementModal from "./EntrainementModal";

const Entrainements = () => {
  const [loading, setLoading] = useState(false);
  const user = useContext(userContext);
  const [showCreateModal, setShowCreateModal] = useState(false);

  //Contrôle du chargement des données
  if (loading) return <Loading />;

  return (
    <>
      <Row className="header">
        <Col sm={4}>
          <Image
            className="img_title"
            src="images/icons/bigmenu_sujets-entrainements.svg"
          />
          <span className="title">Sujets d'entrainement</span>
        </Col>
        {user.userRights && user.userRights.entrainement.create && (
          <Col sm={3}>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => setShowCreateModal(true)}
            >
              <BsPlusLg /> Créer un sujet d'entrainement
            </Button>
          </Col>
        )}
      </Row>
      {showCreateModal && <EntrainementModal show={setShowCreateModal} />}

      {/* {user && user.userRoles == "expert" ? (
        <EntrainementsListCompetitor />
      ) : ( */}
        <EntrainementsListStaff />
      {/* )} */}
    </>
  );
};

export default Entrainements;
